* {
  margin: 0;
  padding: 0;
}

body {
  background-image: url("../../assets/paisley.jpg");
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pamImage {
  float: right;
  margin: 10px;
  border-radius: 50%;
}

@media only screen and (max-width: 500px) {
  #pamImage {
    width: 50%;
  }
}
